import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchBoardList } from './boardSlice'

export const BoardList = () => {
  const boardList = useSelector((state) => state.board.boardList);
  const listStat = useSelector((state) => state.board.listStat);
  const dispatch = useDispatch();

  const [location, setLocation] = useState({});

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });

        if(listStat === 'idle'){
          dispatch(fetchBoardList({latitude: position.coords.latitude, longitude: position.coords.longitude, radius: 100}));
        }
      },
      (error) => console.log(error)
    );
  }, [dispatch, listStat])

  const renderedBoard= boardList.map((board) => {
    let content = {};
    try{
      content = JSON.parse(board.content);
    } catch (e) {
      content.contentText = board.content;
      content.contentImg = [];
    }

    const renderedImg = content.contentImg.map( image => {
      return (<img src={'https://lapine.io/api/filesvc/download?directory='+image.directory+'&filename='+image.savedFileName+'&orgFileName='+image.orgFileName} style={{width: "300pt",height: "auto"}}/> )
    })

    return (
      <article className="excerpt" key={board.id}>
        <h3>제목 : {board.title}</h3>
        <p className="content">글 : {content.contentText}</p>
        {renderedImg}
        <p className="userName">작성자  : {board.userName}</p>
        <p className="latitude">위도 : {board.latitude}</p>
        <p className="longitude">경도 : {board.longitude}</p>
        <p className="createdTime">작성일자 : {board.createdTime}</p>
      </article>
    )
  })

  return (
    <section className="board-list">
      <h2>게시판</h2>
      {renderedBoard}
    </section>
  )
}