import { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addBoard, uploadFile } from './boardSlice'
import imageCompression from "browser-image-compression";

export const AddBoardForm = () => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const [location, setLocation] = useState({});

  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);

  const dispatch = useDispatch()

  const onTitleChanged = (e) => setTitle(e.target.value)
  const onContentChanged = (e) => setContent(e.target.value)

  const onImageChange = (e) => setImages([...e.target.files]);
  const { keycloak } = useKeycloak();

  const getImgUpload = async (image) => {
    const resizingBlob = await imageCompression(image, { maxSizeMB: 0.5 });
    const resizingFile = new File([resizingBlob], image.name, { type: image.type });
    return resizingFile;
  };

  const onSaveClicked = () => {
    let savedImages = [];
    if(!title || !content) return;

    Promise.all(images.map( async image => {
      const formData = new FormData();
      formData.append('file', await getImgUpload(image));
      //formData.append('file', image);
      formData.append('fileName', image.name);
  
      await uploadFile(formData).then( async value=>{
        savedImages = [...savedImages, value];
      },value=>{
        console.log("error" + value);
      });

    })).then(()=>{
      const contentBody = {contentText: content, contentImg: savedImages};
      const contentBodyString = JSON.stringify(contentBody);
      dispatch( addBoard({ title, contentBodyString, latitude: location.latitude, longitude: location.longitude, token: keycloak.token}))
      setTitle('')
      setContent('')
      setImages([]);
      setImageURLs([]);
    });
  }

  useEffect(()=> {
    if(images.length < 1) return ;
    const newImageUrls = [];
    images.forEach(image => newImageUrls.push(URL.createObjectURL(image)));
    setImageURLs(newImageUrls);
  }, [images]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => console.log(error)
    );
  }, []);

  return (
    <section>
      <h2>글 작성</h2>
      <div>
        <h1>Latitude: {location.latitude}</h1>
        <h1>Longitude: {location.longitude}</h1>
      </div>
      <form>
        <label htmlFor="title">제목:</label>
        <input type="text" id="title" name="title" value={title} onChange={onTitleChanged} />
        <label htmlFor="content">내용:</label>
        <textarea id="content" name="content" value={content} onChange={onContentChanged} />

        <input type="file" multiple accept="image/*" onChange={onImageChange} />
        { imageURLs.map(imageSrc => <img src={imageSrc} /> )}

        <button type="button" onClick={onSaveClicked}> 저장 </button>
      </form>
    </section>
  )
}